.reuseablebtn {
  width: fit-content;
  height: 20%;
  border-radius: 5px;
  background-color: var(--primary);
  color: black;
  font-size: 1vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  &:hover {
    background-color: var(--primaryDark);
  }
}
.btndisabled {
  width: fit-content;
  height: 20%;
  border-radius: 5px;
  background-color: #adb5bd;
  color: black;
  font-size: 1vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: not-allowed;
}
.switchDisabled {
  cursor: not-allowed !important;

  /* background-color: #adb5bd4f; */
}

/* The switch - the box around the slider */
.switch {
  font-size: 11px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid #adb5bd;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 20px;
  left: 0.27em;
  bottom: 0.25em;
  background-color: #adb5bd;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary);
}

input:checked + .slider:before {
  transform: translateX(1.4em);
  background-color: #fff;
}

.btnLoader {
  font-weight: 600;
  font-family: sans-serif;
  font-size: 0.8vmax;
  animation: l1 1s linear infinite alternate;
}
.btnLoader:before {
  content: "";
}
@keyframes l1 {
  to {
    opacity: 0;
  }
}
