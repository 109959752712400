::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #333333;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #eeeeee;
  border-radius: 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.disabled {
  cursor: not-allowed;

  /* background-color: #adb5bd; */
}
* {
  font-family: inter !important;
}
.displayFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.displayNone {
  display: none;
}
.puredisabled {
  cursor: not-allowed;
  background-color: #adb5bd4f;
}
.error {
  font-size: 12px;
  color: red;
}
.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;
}
.studioMainScreen {
  width: 88vw;
  height: 100%;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* gap: 5%; */
  overflow: hidden;
}
.studioHeader {
  width: 100%;
  min-height: 8%;
  max-height: 8%;
  /* border: 2px solid green; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
  background-color: white;
}
.studioHeader > div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vmax;
}
.studioHeader > div:nth-child(1) {
  width: 80%;
  height: 60%;
  border: 2px solid #dddddd;
  border-radius: 20px;
}
.studioHeader > div:nth-child(1) > input {
  height: 100%;
  width: 100%;
  padding-right: 5%;
  border: none;
  outline: none;
  border-radius: 20px;
  font-size: 0.8vmax;
  padding-left: 2%;
}
.studioHeader > div:nth-child(1) input::placeholder {
  text-align: center;
  font-size: 0.8vmax;
  color: black;
  font-weight: 540;
}
.studioHeader > div:nth-child(2) {
  width: 2%;
  height: 80%;
  /* border: 2px solid green; */
  margin-left: -5%;
}
.studioHeader > div:nth-child(3) {
  width: 3%;
  height: 80%;
  border: 6px solid transparent;
  flex-direction: column;
  position: relative;
}
.studioHeader > div:nth-child(4) {
  width: 5%;
  height: 80%;
  /* border: 2px solid blue; */
}
.notifyIcon {
  position: absolute;
  top: 0;
  right: 0;
  color: orange;
}
.onboardStudio {
  width: 80%;
  height: 40%;
  /* border: 2px solid red; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.onboardStudio > div {
  display: flex;
  align-items: center;
}

.onboardStudio > div:nth-child(1) {
  width: 40%;
  height: 15%;
  /* border: 2px solid green; */
  font-size: 1vmax;
  color: var(--primary);
  font-weight: 600;
}
.onboardStudio > div:nth-child(2) {
  width: 40%;
  height: 15%;
  /* border: 2px solid green; */
  font-size: 1vmax;
  align-items: flex-start;

  font-weight: 600;
}
.onboardStudio > div:nth-child(3) {
  width: 60%;
  height: 60%;
  /* border: 2px solid green; */
  font-size: 1vmax;

  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3%;
  margin-top: 2%;
}
.onboardStudio > div:nth-child(3) > div {
  width: 20%;
  height: 80%;
  /* border: 2px solid red; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.onboardStudio > div:nth-child(3) > div > div:first-child {
  width: 100%;
  height: 80%;
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3vmax;
}
.onboardStudio > div:nth-child(3) > div > div:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8vmax;
}

.pagination-container {
  display: flex;
  list-style-type: none;
  /* border: 2px solid blue; */

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: var(--primary);
      cursor: pointer;
    }

    &.selected {
      background-color: var(--primary);
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: "";
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.paginationTab {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid red; */
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  div {
    width: 20%;
    height: 100%;
    border: 1px solid #dee2e6;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;

    &:hover {
      background-color: #ffc801ab;
    }
  }
  > div:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  > div:nth-child(1) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transform: rotate(180deg);
  }
}
.notAllow {
  cursor: not-allowed;
  color: gray;
  &:hover {
    background-color: white !important;
    cursor: not-allowed;
  }
}
.activePage {
  background-color: var(--primary) !important;
}
.mainPaginationDiv {
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  height: 100%;
}
.paginationgoto {
  width: 40%;
  /* border: 2px solid pink; */
}
.paginationgoto > input {
  padding-left: 3%;
  height: 3.5vh;
  width: 40%;
  border: none;
  outline: none;
  margin-right: 5%;
  border-radius: 5px;
  /* background-color: #f0f0f0; */
}
.paginationgoto > button {
  width: 30%;
  border-radius: 5px;
  font-size: 0.8vmax;
  background-color: white;
}
.paginationgoto > button:hover {
  background-color: var(--primary);
}
.allStudioDetailsPage {
  width: 100%;
  height: 100%;
  padding-bottom: 1%;
  /* border: 2px solid green; */

  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.allStudiobtndiv {
  width: 95%;
  height: 10%;
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.allStudiobtndiv > div:nth-child(2) {
  width: 28%;
  height: 100%;
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}
.allStudiobtndiv > div:nth-child(1) {
  font-size: 1.3vmax;
  font-weight: 600;
}

.bookingStudiobtn {
  width: 95%;
  min-height: 10%;
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 1sec ease;
}

.bookingStudiobtn > div:nth-child(2) {
  width: 65%;
  height: 100%;
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2%;
}
.bookingStudiobtn > div:nth-child(1) {
  font-size: 1vmax;
  font-weight: 600;
  border: 2px solid transparent;
  width: 35%;
  height: 65%;
}
.bookingStudiobtn > div:nth-child(1) > div {
  /* width: 90.5%; */
  /* min-width: -webkit-fill-available; */
  min-width: fit-content;
  max-width: 68.1%;
  height: 100%;
  /* border: 2px solid pink; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 15px;
  background-color: #adb5bd;
}
.bookingStudiobtn > div:nth-child(1) > div > div:hover {
  background-color: #ffc801ab;
  cursor: pointer;
  color: black;
}
.bookingStudiobtn > div:nth-child(1) > div > div {
  height: 100%;
  width: fit-content;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bookingStudiobtn > div:nth-child(1) > div > div:nth-child(4) {
  border: 2px solid transparent;
  padding-right: 6%;
  padding-left: 5%;
}

/* ----------------------------------------------------------- */

.bookingStudiobtn2 {
  width: 95%;
  min-height: 10%;
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 1sec ease;
}

.bookingStudiobtn2 > div:nth-child(2) {
  width: 28%;
  height: 100%;
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2%;
  padding-left: 1%;
}
.bookingStudiobtn2 > div:nth-child(1) {
  font-size: 1vmax;
  font-weight: 600;
  border: 2px solid transparent;
  /* border: 2px solid red; */
  width: 100%;
  height: 65%;
}
.bookingStudiobtn2 > div:nth-child(1) > div {
  /* width: 90.5%; */
  width: fit-content;
  /* max-width: 68.1%; */
  height: 100%;
  /* border: 2px solid pink; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 15px;
  /* text-wrap: nowrap; */
  background-color: white;
}
.bookingStudiobtn2 > div:nth-child(1) > div > div:hover {
  background-color: #ffc801ab;
  cursor: pointer;
  color: black;
}
.bookingStudiobtn2 > div:nth-child(1) > div > div {
  height: 100%;
  width: fit-content;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  /* padding: 2%; */
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bookingStudiobtn2 > div:nth-child(1) > div > div:nth-child(4) {
  border: 2px solid transparent;
  /* padding-right: 6%;
  padding-left: 5%; */
}
.studioImage {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  /* border: 1px solid rgba(128, 128, 128, 0.479); */
  overflow: hidden;
}
.studioImageNotFound {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  border: 1px solid rgba(128, 128, 128, 0.479);
  overflow: hidden;

  > img {
    width: 50%;
    height: 50%;
    object-fit: contain;
  }
}
.studioImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 10px;
}
.studioTabelDiv {
  width: 95%;
  height: 82%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* border: 2px solid yellow; */
  background-color: white;
  border-radius: 10px;
  margin-top: -2%;
}
.studioTabelDiv small {
  color: rgba(173, 177, 181, 1);
}

.searchDiv {
  /* border: 2px solid red; */
  width: 96%;
  height: 8vh;
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  padding-left: 0.5%;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 80%;
  }
  > div:first-child {
    width: 20%;
    border: 2px solid #d5d8dd;
    border-radius: 10px;
  }
  > div:nth-child(2) {
    width: 72%;
    border: 2px solid #d5d8dd;
    /* border: 2px solid red; */
    border-radius: 10px;
    margin-left: 5%;

    padding-left: 2%;
    input {
      width: 95%;
      height: 100%;
      /* border: 2px solid red; */
      border: none;
      outline: none;
    }
  }
}
.clearFilter {
  border: 2px solid transparent;
  width: 9vw;
  button {
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
    font-size: 1.2vmax;
    transition: ease-in-out 1s;
  }
}
.antCustomcss {
  height: 100%;
  width: 100%;
  border: 1px solid #f3f6f9 !important;
  border-radius: 8px;
  box-shadow: none !important;
}

.antCustomcss:hover,
.antCustomcss:focus {
  border-color: #f3f6f9 !important;
}
.studioTabelDiv > div:nth-child(2) {
  width: 95%;
  height: 85%;
  /* border: 2px solid blue; */

  overflow-y: auto;
  overflow-x: hidden;
  /* padding-bottom: 2%; */
}
.studioTabelDiv > div:nth-child(2) table {
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
  /* table-layout: fixed; */
  /* height: 100%; */

  /* border: 2px solid red; */
}
table {
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
  /* border: 2px solid green; */
}
.studiotabelHead {
  width: 100%;
  height: 50px;
  background-color: rgba(243, 246, 249, 1);
  text-align: left;
  /* border: 2px solid red; */
}
.studiotabelHead > tr {
  width: 80%;
  /* border: 2px solid green; */
  > th:nth-child(1) {
    padding-left: 10px;
  }
}
.headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 60%; */
  /* padding-left: 1%; */
  /* border: 2px solid red; */
}
.shortTableData {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shortTableData:hover {
  overflow: visible;
  white-space: normal;
  background-color: #f0f0f0; /* Optional: Highlight on hover */
}
.filterBox {
  width: 1vw;
  height: 4vh;
  /* border: 2px solid red; */
  display: inline-block;
  margin-left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  user-select: none;

  &:hover {
    /* background-color: #ffc70133; */
    background-color: rgb(235, 235, 235);
  }
  > span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
}
.filteractionBox {
  position: absolute;
  font-size: 1vmax;
  font-weight: 400;
  width: 15vw;
  min-height: fit-content;
  max-height: fit-content;
  padding: 10%;
  /* border: 2px solid red; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  top: 50%;
  left: 80%;
  z-index: 9000;
  background-color: white;
  transition: 1s ease-in-out;
  border-radius: 10px;
  /* border: 2px solid green; */

  > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 1%;
    padding-top: 1%;
    /* border: 2px solid red; */
    > p {
      color: gray;
    }
    > input {
      max-width: 40%;
      padding: 2%;
      border-radius: 5px;
      outline: none;
      border: none;
      border: 2px solid rgba(0, 0, 0, 0.25);

      /* border-color: rgba(0, 0, 0, 0.25); */
    }
  }
}
.topborder {
  border-top: 2px solid #f2f5f8;
}

.filteractionBox2 {
  position: absolute;
  font-size: 1vmax;
  font-weight: 400;
  width: 9vw;
  min-height: fit-content;
  max-height: fit-content;
  /* border: 2px solid red; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  top: 85%;
  left: 43%;
  background-color: white;
  transition: 1s ease-in-out;
  border-radius: 10px;
  z-index: 1000;
  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5%;

    padding-bottom: 2%;
    /* border: 2px solid red; */
    padding-top: 4%;
    > p {
      color: gray;
    }
    > input[type="text"] {
      max-width: 80%;
      padding: 3%;
      border-radius: 5px;
      outline: none;
      border: none;
      border: 2px solid rgba(0, 0, 0, 0.25);
      /* border-color: rgba(0, 0, 0, 0.25); */
    }
    > input[type="checkbox"] {
      margin: 0;
    }
  }
}
.fltercheckboxdiv {
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5%;
  input[type="checkbox"] {
    accent-color: var(--primary);
  }
}
.permissionMainCheckboxDiv {
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5%;
  height: 15%;
  font-size: 1vmax;
  font-weight: 500;
  input[type="checkbox"] {
    accent-color: var(--primary);
  }
}
.permissionCheckboxDiv {
  width: 50%;
  height: 50%;
  border: 2px solid #dddddd;
  border-radius: 10px;
  overflow-y: scroll;
}
.studiotabelHead > tr > th:nth-child(1) {
  width: 25%;
  /* display: flex;
  align-items: center; */
  /* justify-content: space-around; */
}

.label {
  font-size: 1vmax;
  font-weight: 600;
}
.studiotabelHead > tr > th:nth-child(2) {
  width: 15%;
}
.studiotabelHead > tr > th:nth-child(3) {
  width: 25%;
}
.studiotabelHead > tr > th:nth-child(4) {
  width: 15%;
}

td {
  text-align: left !important;
  /* padding-left: 2%; */
  padding: 1rem;
  padding-left: 0.5rem;
  /* padding-left: 2%; */
  /* text-align: left; */
  /* border-bottom: 1px solid #ddd; */
  /* text-align: left; */
}
.customUserTd > td {
  text-align: start !important;
}
.tableActionbtn {
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  width: 100%;
  height: 100%;
  /* border: 2px solid red; */
  margin-bottom: 5%;
}
.tableActionbtn > div:nth-child(2) {
  width: 35%;
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2vmax;
  height: 40%;
}
.tabelpaginationDiv {
  width: 95%;
  /* border: 2px solid red; */
  height: 6%;
  display: flex;
  align-items: flex-end;
  margin-top: 1%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.tableActionbtn > div:nth-child(1) {
  width: 60%;
  /* border: 2px solid pink; */
}
.tableActionbtn select {
  /* background-color: red; */
  color: black;
  border: none;
  outline: none;
  width: 100%;
  width: 100%;
  height: 100%;
  padding: 5%;
  border-radius: 5px;
}
.tableActionbtn option {
  background-color: white;
  color: black;
}
.studioFooter {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 2px solid green; */
  background-color: white;
  padding-left: 2.5%;
  padding-right: 2.5%;
  margin-top: 1%;
  margin-bottom: -0.3%;
}

.addNewStudioTitle {
  width: 70%;
  height: 10%;
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5vmax;
  font-weight: 600;
}

.addNewStudioPage {
  width: 70%;
  height: 95%;
  /* border: 2px solid red; */
  overflow-y: auto;
  /* position: relative; */
}

.showmode {
  width: 100%;
  height: 97%;
  min-height: inherit;
  position: absolute;
  top: 0;
  z-index: 1000;
  /* background-color: rgba(128, 128, 128, 0.055); */
  -webkit-user-select: none;
  user-select: none;
}
.addNewStudioPage > div {
  width: 100%;
  /* height: 100%; */
  /* border: 2px solid blue; */
  /* border-radius: 10px; */
  background-color: white;
  /* overflow-y: scroll; */
  overflow-x: hidden;
}
.addNewStudioPage > div:first-child {
  display: flex;
  justify-content: space-around;
  /* border: 2px solid red; */
  /* min-height: 100% ;
  height: auto; */
  height: 110%;
}
.addNewStudioPage > div > div {
  width: 45%;
  height: 100%;

  /* border: 2px solid pink; */
  padding-top: 2%;
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.timeSlotDiv {
  width: 70%;
  height: 95%;
  /* border: 2px solid red; */
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: relative; */
}
.mainSlotDiv {
  width: 90%;
  height: 100%;
  border: 20px solid transparent;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  > div:first-child {
    width: 100%;
    height: 15%;
    /* border: 2px solid red; */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.counterMaindiv {
  /* border: 2px solid pink; */
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > div:first-child {
    width: 30%;
    height: 100%;
    /* border: 2px solid green; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0%;
  }
  small {
    color: gray;
  }
}
.counterDiv {
  /* border: 2px solid red; */
  width: 60%;
  height: 100%;
  font-size: 1.2vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
  -webkit-user-select: none;
  user-select: none;
}
.allSlots {
  width: 100%;
  height: 85%;
  /* border: 2px solid yellow; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2%;
}
.selectSlotDiv {
  width: 100%;
  height: 6vh;
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2%;
  /* gap: 8%; */
}
.slots {
  width: 23%;
  height: 100%;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 1vmax;
  &:hover {
    background-color: #ffc80188;
  }
}

.slots.selected {
  background-color: var(--primary);
}

.slots.unavailable {
  /* background-color: #d3d3d3;  */
  color: #a9a9a9; /* dark gray */
  cursor: not-allowed;
  border-color: gray;
}
.selected {
  background-color: var(--primary);
}
.Discography {
  /* border: 2px solid red; */
  width: 100%;
  height: 7vh;
  margin-top: 2%;

  /* justify-content: center; */
  position: relative;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > div > div > img {
    position: absolute;
    top: -10%;
    right: -1%;
    cursor: pointer;
  }
  > div > input {
    width: 100%;
    height: 6vh;
    outline: none;
    border: none;
    border: 2px solid #dddddd;
    border-radius: 5px;
  }
}
.addNewStudioinputBox {
  width: 100%;
  min-height: 9vh;
  /* max-height: 9vh; */
  max-height: 9vh !important;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;

  gap: 10%;

  > div {
    height: 100%;
  }
}
.addNewStudioinputBox input,
.addNewStudioinputBox select {
  border-radius: 10px;
  height: 6vh;
  /* height: 50%; */
  width: 100%;
  outline: none;
  border: 2px solid #dddddd;
  padding-left: 2.5%;
  color: black;
}
.addNewStudioinputBox > label {
  font-size: 1vmax;
  font-weight: 600;
}
.addNewStudioinputBox select {
  padding: 1.8%;
  margin-top: 0%;
  height: 100%;
}

.customInput {
  width: 100%;
  max-height: 10vh;
  /* max-height: 9vh; */
  min-height: fit-content;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;

  gap: 4px;

  > div {
    height: 100%;
  }
}
.customInput input,
.customInput select {
  border-radius: 10px;
  height: 5vh;
  /* height: 50%; */
  width: 100%;
  outline: none;
  border: 2px solid #dddddd;
  padding-left: 5%;
  color: black;
}
.customInput > label {
  font-size: 1vmax;
  font-weight: 600;
}
/* .customInput select {
  padding: 1.8%;
  margin-top: 0%;
  height: 50%;
} */
.addPriceAndCountryInput {
  width: 98.9%;
  border: 2px solid transparent;
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 4%;
  > div {
    width: 48%;
    border: 2px solid #dddddd;
    height: 90%;
    border-radius: 10px;
    > select,
    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      border-radius: 10px;
      color: #7575759a;
    }
    > input {
      color: black;
    }
  }
}
.editPencilinput {
  position: relative;
  padding-bottom: 2%;
}
.editpencil {
  position: absolute;
  bottom: 25%;
  right: 2%;
  cursor: pointer;
  z-index: 1000;
}
.amenitesCheckbox {
  border: 2px solid #dddddd;
  width: 100%;
  height: 30%;
  border-radius: 10px;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  gap: 5%;
  overflow-y: scroll;
}
.amenitesCheckbox > div {
  /* border: 2px solid red; */
  padding-left: 1%;
  font-size: 1vmax;

  /* align-items: center;
  justify-content: center; */
}
.amenitesCheckbox input[type="checkbox"] {
  accent-color: var(--primary);
}
.addNewStudioimgBox {
  /* border: 2px solid red; */
  width: 100%;
  min-height: 25vh;
  max-height: 25vh;
}
.addNewStudioimgBox label {
  font-size: 1vmax;
  font-weight: 600;
  width: 100%;
  height: 100%;
  /* border: 2px solid red; */
}
.loderdiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addNewStudioimgBox > div {
  margin-top: 2%;
  width: 100%;
  border: 2px dashed #dddddd;
  height: 16vh;
  border-radius: 10px;
}

.addNewStudioimgBox > div > label {
  width: 100%;
  height: 16vh;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.addNewStudioimgBox > div > input {
  display: none;
}
.addNewStudioimgBox > div > label span {
  color: var(--primary);
}
.addNewStudioimgBox > div > label > div:first-child > img {
  width: 40px;
  height: 30px;
}
.showMultipleStudioImage {
  width: 100%;
  height: 100%;
  padding-top: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* border: 2px solid red; */
  img {
    object-fit: cover;
  }
}
.showMultipleStudioImage > div:nth-child(1) {
  width: 95%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
  /* border: 2px solid green; */
}
.showMultipleStudioImage > div:nth-child(2) {
  align-self: flex-start;
  padding-left: 2%;
}

.showMultipleStudioImage > div:nth-child(1) > div {
  /* Add any styling for each grid item */
  border: 1px solid #ccc;
  background-color: grey;
  width: 18%;
  height: 80%;
  object-fit: cover;
  position: relative;
  border-radius: 10px;
  text-align: center;
}
.imageuploadspan {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100% !important;
  /* border: 2px solid green; */
  margin-top: 2%;
  height: 20%;
}
.showlocationDiv {
  width: 100%;
  height: 15%;
  /* border: 2px solid red; */
}
.addNewStudioPage > div:nth-child(2) {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* border: 2px solid red; */
  min-height: 82%;
  height: fit-content;
}
.addNewStudioPage > div:nth-child(2) > div {
  width: 95%;
  height: 90%;
  /* border: 2px solid green; */
}
.addNewStudioinputBox2 {
  width: 100%;
  min-height: 16vh;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.addNewStudioinputBox2 textarea {
  border-radius: 10px;
  min-height: 12vh;
  max-width: 100%;
  min-width: 50%;
  max-height: 50vh;
  outline: none;
  border: 2px solid #dddddd;
  padding-left: 1%;
  color: black;
  /* font-size: 0.8vmax; */
  padding-top: 1%;
}
.addNewStudioinputBox2 > label {
  font-size: 1vmax;
  font-weight: 600;
}
.roomAndClassSection {
  width: 100%;
  height: 34%;
  /* border: 2px solid red; */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  > div:first-child {
    /* border: 2px solid red; */
    width: 50%;
    height: 100%;
  }
}
.roomAndClassSection > div {
  width: 48%;
  /* border: 2px solid red; */
  height: 100%;
}
.addNewStudioinputBox3 {
  width: 100%;
  height: 90%;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  gap: 10%;
}
.addNewStudioinputBox3 input {
  border-radius: 10px;

  width: 100%;
  height: fit-content;
  outline: none;
  border: 2px solid #dddddd;
  padding-left: 5%;
  color: black;
}
.addNewStudioinputBox3 > label {
  font-size: 1vmax;
  font-weight: 600;
}
.addTeamDetailDiv {
  /* width: 100%; */
  /* min-height: 50%; */
  min-height: 18vh;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  gap: 5%;
}
.addTeamDetailDynamicDiv {
  /* overflow-y: scroll; */
  display: flex;
  align-items: center;

  flex-direction: column;
  /* border: 2px solid pink; */
  gap: 5%;
  border-radius: 10px;

  width: 100%;
  min-height: 20%;

  /* height: fit-content; */
  outline: none;

  color: black;
  /* overflow: scroll; */

  padding-top: 3%;

  input {
    background-color: transparent;
  }
  /* border: 2px solid pink; */
}

.addTeamDetailDiv > label {
  padding-top: 2%;
  font-size: 1vmax;
  font-weight: 600;
}

.addTeamDetailMainDiv {
  width: 95%;
  min-height: 10vh;

  border: 2px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: relative;
  padding-left: 2%;
  margin-bottom: 5%;
  /* border: 2px solid red; */
  /* flex-direction: column; */
}

.addTeamDetailMainDiv > div:nth-child(1) {
  width: 20%;
  height: 7vh;
  border: 2px dashed black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5vmax;
  color: var(--primary);
}
.addTeamDetailMainDiv > div:nth-child(1) > div {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* overflow: hidden; */
  position: relative;
}
.addTeamDetailMainDiv > div:nth-child(1) > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.addTeamDetailMainDivImg {
  max-width: 100px;
  max-height: 100px;
}
.addTeamDetailMainDiv > div:nth-child(1) > div > span {
  position: absolute;
  top: -20%;
  right: -20%;
}
.addTeamDetailMainDiv > div:nth-child(2) {
  /* border: 2px solid blue; */
  margin-left: 10%;
}
.addTeamDetailMainDiv > div:nth-child(2) input:nth-child(1) {
  font-size: 1vmax;
  font-weight: 600;
}
.addTeamDetailMainDiv > div:nth-child(2) input {
  width: 95%;
  border: none;
  outline: none;
  /* border: 2px solid red; */
}
.addTeamDetailbtn {
  /* position: absolute; */
  align-self: start;
  bottom: 0%;
  font-size: 1vmax;
  left: 2%;

  color: black;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2%;
  padding-bottom: 2%;
  cursor: pointer;
}

.addNewRoomPage {
  width: 70%;
  height: 95%;
  /* border: 2px solid red; */
  overflow-y: scroll;
}
.addNewRoomPage > div {
  width: 100%;
  min-height: fit-content;
  height: 140%;
  /* border: 2px solid blue; */
  border-radius: 10px;
  /* max-height: fit-content; */
  background-color: white;
  padding-bottom: 5%;
  overflow-y: scroll;
}
.addNewRoomPage > div:first-child {
  display: flex;
  justify-content: space-around;
}
.addNewRoomPage > div:last-child > div:last-child {
  /* border: 2px solid green; */
  height: 108% !important;
  overflow-y: scroll;
  /* min-height: fit-content; */
}
.addNewRoomPage > div:last-child > div:last-child::-webkit-scrollbar {
  width: 0px !important;
}
.addNewRoomPage > div > div {
  width: 45%;
  height: 100%;
  /* border: 2px solid pink; */
  padding-top: 2%;
  display: flex;
  gap: 2%;
  flex-direction: column;
}

.addNewRoomPage > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addNewRoomPage > div:nth-child(2) > div {
  width: 95%;
  height: 90%;
  /* border: 2px solid green; */
}
.defaultLabel {
  font-size: 1vmax;
  font-weight: 600;
}
.cancelTeamDetailUpload {
  position: absolute;
  /* float: left; */
  top: -10%;
  right: -2%;
  font-size: 1.1vmax;

  color: var(--primary);
  cursor: pointer;
  /* mix-blend-mode: color-burn black; */
}
.cancelImageUpload {
  position: absolute;
  /* float: left; */
  top: -12%;
  right: 0%;
  font-size: 1vmax;

  color: var(--primary);
  cursor: pointer;
  /* mix-blend-mode: color-burn black; */
}
.cancelDetailsUpload {
  position: absolute;
  /* float: left; */
  top: 20%;
  right: 0%;
  font-size: 1vmax;

  color: var(--primary);
  cursor: pointer;
  /* mix-blend-mode: color-burn black; */
}
.addNewStudioPage .addNewStudioinputBox {
  height: 20%;
}
/* ---------------userProfile Section Start-------- */
.userProfileSection {
  width: 100%;
  height: 100%;
  /* border: 2px solid red; */

  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > div:first-child {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > :last-child {
    align-self: flex-end;
  }
}
.profilesidebar {
  width: 20%;
  height: 100%;
  border: 2px solid #d8dadf;
  > div {
    width: 100%;
    height: 10%;
    border: 2px solid #d8dadf;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10%;
    gap: 2%;
    font-size: 0.9vmax;
    font-weight: 600;
    cursor: pointer;
  }
}

.profilesection {
  width: 80%;
  height: 100%;
  /* border: 2px solid blue; */
  /* border: 2px solid #d8dadf; */

  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 90%;
    height: 90%;
    background-color: #f0f0f0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      padding-top: 3%;
    }
    > div:first-child {
      width: 30%;
      height: 100%;
      /* border: 2px solid red; */
    }
    > div:last-child {
      width: 70%;
      height: 100%;
      /* border: 2px solid red; */
    }
  }
}
.profileImgDiv {
  /* border: 2px solid yellow; */
  width: 100%;
  height: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.profileImg {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  /* border: 2px solid red; */
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.switchDiv {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}
.userDetails {
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 70%;
    height: 90%;
    /* border: 2px solid green; */
  }
}
.userDetails input {
  height: 4.8vh;
  background-color: white;
  &:hover {
    cursor: not-allowed;
  }
}
.UserbookingDetails {
  width: 80%;
  height: 100%;
  border: 2px solid #d8dadf;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f0f0f0; */
  > div {
    width: 95%;
    height: 90%;
    border-radius: 10px;
    /* border: 2px solid red; */
  }
}
.userProjectStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid black; */
  border-radius: 5px;
  font-size: 0.7vmax;
  font-weight: 600;
  padding: 0.5em;
}
.leftBorder {
  border-left: 5px solid var(--primary) !important;
}
/* ---------------userProfile Section end-------- */

/* ---------------Show All Bookings  Section start-------- */
.showAllBookings {
  width: 100%;
  height: 100%;
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.BookingInvoicePage {
  width: 70%;
  height: 95%;
  /* border: 2px solid red; */
  background-color: white;
  overflow-y: scroll;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
  /* justify-content: center; */
  > div {
    /* border: 2px solid green; */
    width: 95%;
    /* height: 30vh; */
  }
  p,
  b {
    color: #667085;
    margin: 8px 0px 8px 0px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
}
.invoiceHeader {
  height: 30%;
  min-height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  /* border: 2px solid red; */
}
.invoiceStudioImageDiv {
  width: 12%;
  height: 8em;
}
.invoiceStudioImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.invoiceStudioDetails {
  /* line-height: 0.8rem; */
  width: 40%;
  height: 100%;
  /* border: 2px solid pink; */
  > b {
    color: #667085;
  }
}
.invoiceHeaderPrice {
  /* border: 2px solid yellow; */
  height: 100%;
  margin-left: auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  margin-left: auto;
  b {
    color: black;
    font-size: 1vmax;
  }
  > div:last-child {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
  }
}
.invoiceBody {
  margin-top: 10px;
  border: 3px solid #fafafa;
  height: 80vh;
  border-radius: 15px;
  padding: 3vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  min-height: fit-content;
  gap: 10px;
  > div {
    /* border: 2px solid pink; */
    /* height: 25vh; */
    width: 100%;
  }
}
.invoiceBodyHeader {
  width: 60%;
  max-height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  > div:first-child {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    /* border: 2px solid yellow; */
    border-radius: 15px;
    gap: 0.8rem;
    min-width: fit-content;
    padding-right: 10%;
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    padding-left: 1vmax;
    background-color: #fafafa;
  }
}
.invoiceBodyTable {
  min-height: fit-content;
}
.summaryRow td {
  border: none !important;
}
.invoiceFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
/* .totalPriceLine{
  position: relative;
  height: 1px;
  > hr{
position: absolute;
 border: 2px solid red;
width: 20vw;
top: -20% !important;
  }
} */
@media print {
  * {
    all: unset !important;
  }
}
/* ---------------Show All Bookings  Section end-------- */
.overviewPage1 {
  width: 100%;
  height: 100%; /* Make sure it takes the full viewport height */

  background-color: #f0f0f0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; /* Change to flex-start for alignment */
  flex-direction: column;
  padding-left: 2.5%;
  padding-right: 2.5%;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden;
  /* border: 2px solid red; */
  padding-bottom: 1%;
}

.overviewPageHeader {
  width: 100%;
  min-height: 24vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 2px solid green; */
}

.overviewTicketDiv {
  width: 24%;
  height: 90%;
  overflow: hidden;
  border-radius: 15px;
  background-image: url("../../../assets/img/adminOverview/Subtract.png");
  object-fit: contain;
  background-size: 100% 95%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid red; */
}

.overviewTicketDiv > div {
  width: 47%;
  height: 95%;
  border-radius: 15px;
  padding-left: 2%;
  overflow: hidden;
}

.overviewTicketDiv > div:last-child > img {
  width: 100%;
  height: 90%;
  object-fit: contain;
}

.transactionChart {
  width: 100%;
  min-height: 69vh;
  border-radius: 15px;
  background-color: white;
  /* border: 2px solid red; */
}
.donutChart {
  width: 100%;
  /* min-height: 100%; */
  /* border: 2px solid red; */
  border-radius: 15px;
  padding-bottom: 4%;
  background-color: white;
}

.chartNav {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2%;
  padding-right: 2%;
  > div:first-child {
    width: 50%;
    height: 100%;
    /* border: 2px solid green; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3%;
  }
  > div:last-child {
    width: 50%;
    height: 100%;
    /* border: 2px solid green; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5%;
  }
}
.chartlogo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vmax;
  font-weight: 700;
}
.chartSelect {
  width: fit-content;

  height: 40%;
  background-color: var(--primary);
  border: 2px solid var(--primary);
  outline: none;
  border-radius: 5px;
  font-size: 1vmax;
  font-weight: 500;
}
.overviewPage2 {
  width: 100%;
  min-height: fit-content !important;
  padding-bottom: 8%;
  /* min-height: 87vh; */
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* gap: 8%; */
  gap: 5%;
  > div {
    width: 50%;
    height: 80%;
    /* border: 2px solid yellow; */
  }
}
.overviewPage3 {
  width: 100%;
  min-height: 130vh;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5%;
  > div {
    width: 100%;
    min-height: 40%;
    /* border: 2px solid yellow; */
  }
}
.overviewPage4 {
  width: 100%;
  min-height: 60vh;
  /* border: 2px solid red; */
  > div {
    width: 100%;
    height: 100%;
  }
}
.donutChartDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.customTooltip {
  background-color: white;
  width: fit-content;
  height: fit-content;
  font-size: 1vmax;
  padding: 0.2em;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* -------------promotionSection start------------- */
.PromotionSidebar {
  width: 20%;
  height: 100%;
  border: 2px solid #d8dadf;
  > div {
    width: 100%;
    height: 10%;
    border: 2px solid #d8dadf;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center !important;
    padding-left: 10%;
    gap: 5%;
    font-size: 1vmax;
    font-weight: 600;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    -webkit-user-select: none;
    user-select: none;
    > div {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      gap: 3%;
      -webkit-user-select: none;
      user-select: none;
    }
  }
  small {
    font-size: 0.7vmax;
    font-weight: 500;
    color: #32363e;
    -webkit-user-select: none;
    user-select: none;
  }
}

.bannerPage {
  width: 100%;
  /* height: 85vh;
   border*/
  height: 100%;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;
  -webkit-user-select: none;
  user-select: none;
  padding-right: 2%;
  > div {
    width: 100%;
    /* min-height: 40vh; */
    max-height: fit-content !important;
    /* border: 2px solid #e2e2e2; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 10px;
  }
  span {
    font-size: 1vmax;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 5vh;
    > div:first-child {
      width: 20%;
      height: 100%;
      display: flex !important;
      align-items: center !important;
      justify-content: flex-end !important;
      /* border: 2px solid red; */
      gap: 10%;
    }
    /* border: 2px solid yellow; */
  }
}

.bannerMain {
  width: 100%;
  /* border: 2px solid black; */
  min-height: fit-content;
  max-height: fit-content;
  border-radius: 20px;
  background-color: #f0f0f0;
  padding-top: 2%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 2%;
  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* border: 2px solid red; */
  }
}
.bannerMainContent {
  width: 90%;
  min-height: 10vh;
  border: 2px solid #e2e2e2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 15px;
  background-color: white;

  > div:first-child {
    width: 12%;
    height: 70%;
    /* border: 2px solid red; */
    border-radius: 10px;
    overflow: hidden;
    > img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  > div:nth-child(2) {
    width: 80%;
    height: 60%;
    /* border: 2px solid #e2e2e2; */
    border: 2px solid green;
    border-radius: 10px;
    font-size: 0.9vmax;
    > input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      border-radius: 10px;
    }
  }
  > div:nth-child(3) {
    font-size: 1vmax;
    cursor: grab;
  }
  > label {
    width: 6%;
    height: 70%;
    /* border: 2px solid red; */
    border-radius: 10px;
    overflow: hidden;
    > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
.uploadBannerDataDiv {
  width: 95%;
  height: 10vh;
  border: 2px solid pink;
  border-radius: 10px;
}
.DiscountPage {
  width: 100%;
  height: 100%;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  > div:first-child {
    width: 100%;
    height: 6vh;
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 5%;
    font-size: 1vmax;
    font-weight: 600;
  }
  > div:nth-child(2) {
    margin-top: 2%;
    width: 95%;
    height: fit-content;
    border: 2px solid #dddddd;
    border-radius: 15px;
    padding-top: 2%;
    padding-bottom: 2%;
  }
}

.AddNewBannerPage {
  width: 100%;
  min-height: 100%;
  max-height: fit-content;
  /* border: 2px solid red; */
  /* overflow-y: auto; */
  > div:nth-child(1) {
    width: 100%;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1vmax;
    font-weight: 600;
  }
}
.AddNewBannerMain {
  width: 100%;
  min-height: 30vh;
  border: 2px solid #dddddd;
  border-radius: 15px;
  max-height: fit-content;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1vmax;
  font-weight: 600;
  padding: 20px 0 20px 0;
  /* border: 2px solid red; */
  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    /* border: 2px solid green; */
  }
  > div {
    width: 45%;
    height: 10%;
    /* border: 2px solid green; */
  }
  > div:nth-child(1) {
    width: fit-content;
    /* border: 2px solid red; */
  }
}
.AddBannerImage {
  width: 15vw;
  height: 18vh;
  /* border: 2px dashed #f0f0f0; */
  border-radius: 10px;
  margin-top: 10px;
  background-color: white !important;
  > div {
    width: 100%;
    height: 100%;
    /* border: 2px solid green; */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1vmax;
    font-weight: 600;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    > img {
      object-fit: contain;
      width: 30%;
      height: 30%;
    }
    span {
      color: var(--primary);
    }
  }
}
/* --------------------DiscountPage ------------------------- */
.addNewDiscountPage {
  width: 100%;
  height: 100%;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  > div {
    width: 45%;
    height: 100%;
    /* border: 2px solid green; */
  }
}
/* -------------------IntegrationPage-------------------------- */
.IntegrationPage {
  width: 100%;
  height: 100%;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  > div:first-child {
    width: 100%;
    height: 6vh;
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 5%;
    font-size: 1vmax;
    font-weight: 600;
    /* border: 2px solid red; */
    margin-bottom: 2%;
  }
  > div:nth-child(2) {
    width: 100%;
    height: 94%;
    /* border: 2px solid #dddddd; */
    border-radius: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
}
.integrationMain {
  width: 100%;
  height: 9vh;
  /* border: 2px solid red; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2% 0 2%;
  background-color: #f0f0f0;
  margin-bottom: 15px;
  > span {
    font-size: 1vmax;
    font-weight: 600;
  }
}
.addNewListing {
  position: absolute;
  width: 100vw;
  height: 100vh;
  /* border: 2px solid red; */
  margin-left: -12%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.336);
}

.addNewListingPopUp {
  transition: 1s all ease-in-out;
  width: 40%;
  height: 80%;
  margin-top: -100%;
  /* border: 2px solid red; */
  z-index: 300;
  background-color: white;

  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  > div {
    /* border: 2px solid red; */
    width: 100%;
  }
  > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: green; */
    padding: "0% 5% 0% 5%";
    padding-left: 5%;
    padding-right: 5%;
    height: 10%;
  }
  > div:nth-child(2) {
    height: 80%;
  }
  > div:nth-child(3) {
    display: flex;
    height: 10%;
    align-items: center;
    justify-content: space-between;
    /* background-color: pink; */
    padding: "0% 5% 0% 5%";
    padding-left: 20%;
    padding-right: 5%;
  }
}
.addNewListingPopUpAfter {
  transition: 1s all ease-in-out;

  margin-top: 0;
}

.addNewListingPopUpMain {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background-color: green; */
  > div:nth-child(1) {
    /* border: 2px solid blue; */
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    > div {
      width: 50%;
      height: 10%;
      border: 2px solid #dddddd;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      cursor: pointer;
    }
  }
}
.addNewListingPopUpAvailability {
  /* background: pink; */
  /* border: 2px solid blue; */
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 2%;
  padding-right: 2%;

  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 5%;
  > div:nth-child(1) {
    display: flex;
    gap: 5px;
  }
  /* > div:nth-child(2),
  > div:nth-child(4) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
    border: 2px solid blue;
  } */
}
.addNewListingPopUpAvailabilityTime {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
  /* border: 2px solid blue; */
}
@media (min-width: 920px) and (max-width: 1440px) {
  .overviewPageHeader {
    /* border: 2px solid pink; */
    min-height: 29vh;
  }
  .overviewTicketDiv {
    /* height: 20vh; */
    min-height: fit-content;
  }
  .overviewPage2 {
    min-height: 110vh;
  }
  .overviewPage3 {
    min-height: 180vh;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1903px) {
  .addNewStudioPage .addNewStudioinputBox {
    height: 10%;
    /* background-color: red; */
  }
  .overviewPage3 {
    min-height: 148vh;
  }
}
